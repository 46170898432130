div.gallery {
    margin: 5px;
    border: 1px solid #ccc;
    float: left;
    width: 180px;
    border-radius: 5px;
  }
  
  div.gallery:hover {
    border: 1px solid #777;
    border-radius: 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }
  
  div.gallery img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  div.desc {
    padding: 5px;
    text-align: center;
  }

  .myFieldset,.p-fieldset .p-fieldset-content {
       padding: 0px !important;


    }
  
    .myFieldset,.p-fieldset .desclabel{
      font-size: 10px !important;
      font-weight: 600;
    }